import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Features/PTZ_Tour/SuccessBox';
import PTZTourTable from 'components/Web_User_Interface/1080p_Series/Features/PTZ_Tour/ptzTourTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // PTZ Tour",
  "path": "/Web_User_Interface/1080p_Series/Features/PTZ_Tour/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.",
  "image": "./WebUI_1080p_SearchThumb_Features_PTZ_Tour.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Features_PTZ_Tour.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Features Menu // PTZ Tour' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.' image='/images/Search/WebUI_1080p_SearchThumb_Features_PTZ_Tour.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Features_PTZ_Tour.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Features/PTZ_Tour/' locationFR='/fr/Web_User_Interface/1080p_Series/Features/PTZ_Tour/' crumbLabel="PTZ Tour" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/141ba5f01d6f0a230eba12998a691aff/bb27a/1080p_Settings_Features_PTZ_Tour.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC00lEQVQoz3WRX0hTURzH7x6ivyZqmK0U02amc4qZhBHqpktl/o0QJStcmhMq090/Z9u955x7B0GQmhDVS0+JShg6UQgjEyLoLaO3kl6ichFi2O42d++JM7Meos/Tj8Pvw/f3+x2G4zg+jgA8XsCzaLB3ZNE1snhl+EXn7QXn4ELn4IJz8Lnr7quBmw9FjyCK0Ol0mi2WgsIihuMFlhM4jpdl2VFX29DY8nlN+xgMLa+EPqyElldUWnxZD6okMPkEXOhwn794qa6lobSsLOcY4+aFAZbneN7vVyoqbdaqGkJ0LarqWpRoURKLEn2D1oS8Dkw8uNZ9q0eUGjuEM+VNRScYTqDJPM9jWa531PZfdWoaUVU1HA5HotQhhGixGCFkZnTyRl3HUM/lYVdfQ3VXYX4pHZsmc7ys+Ovsts7Wek0j4bD646f6dVXVtVg0GouoYULI2P17U+7md7L17VD7FObc9hIGAMDyVPb7lcoKW3mFNapvRCIRNRxZW48QPRYP3iCEPAuMdtcfbyrKkx0Z813Gp525DBAAPRjd2V9Zaa2yV9NBdaLrOiHke0h/v0pCn4Ik+O3R+Fjawexck3lfitG0P7m/JJlhOd4d/yxFUartdrO5IDA9M/F48s3L2bnZwMz01Ozc/OTQnaXRcfZ6X8YBY47paHJyUsLunbbMHYwAgAAAPRjGDoeDYRiDwWBJYnJTaGk/vHepKaHXnHjqbLPFejo7N9uYlb4nYdf2bYaTaQa6MwBAEAQIYXtbW0qq0V6c2VOTlZh6yGw64svLZ00mmzm/pLqsydXa0n2urKHcUlyYZ8lPz8r+LW8iiiJCWMFIgghhhBFSIOIg9EgQ+iQkSX6/Ql8xQhBijP7KQlyWMUYYY0SBiPbJ+A8IQrTJZgvj8QAPAB7PVjLGWw3/Bcahcj9Lr93P8iwveL0+n0/0+kSfKEpxIIT/mpIkiaLo9Xp/Ae6ArKAhnANZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/141ba5f01d6f0a230eba12998a691aff/e4706/1080p_Settings_Features_PTZ_Tour.avif 230w", "/en/static/141ba5f01d6f0a230eba12998a691aff/d1af7/1080p_Settings_Features_PTZ_Tour.avif 460w", "/en/static/141ba5f01d6f0a230eba12998a691aff/7f308/1080p_Settings_Features_PTZ_Tour.avif 920w", "/en/static/141ba5f01d6f0a230eba12998a691aff/b0250/1080p_Settings_Features_PTZ_Tour.avif 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/141ba5f01d6f0a230eba12998a691aff/a0b58/1080p_Settings_Features_PTZ_Tour.webp 230w", "/en/static/141ba5f01d6f0a230eba12998a691aff/bc10c/1080p_Settings_Features_PTZ_Tour.webp 460w", "/en/static/141ba5f01d6f0a230eba12998a691aff/966d8/1080p_Settings_Features_PTZ_Tour.webp 920w", "/en/static/141ba5f01d6f0a230eba12998a691aff/f16bf/1080p_Settings_Features_PTZ_Tour.webp 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/141ba5f01d6f0a230eba12998a691aff/81c8e/1080p_Settings_Features_PTZ_Tour.png 230w", "/en/static/141ba5f01d6f0a230eba12998a691aff/08a84/1080p_Settings_Features_PTZ_Tour.png 460w", "/en/static/141ba5f01d6f0a230eba12998a691aff/c0255/1080p_Settings_Features_PTZ_Tour.png 920w", "/en/static/141ba5f01d6f0a230eba12998a691aff/bb27a/1080p_Settings_Features_PTZ_Tour.png 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/141ba5f01d6f0a230eba12998a691aff/c0255/1080p_Settings_Features_PTZ_Tour.png",
              "alt": "Web User Interface - 1080p Series - Features PTZ Tour",
              "title": "Web User Interface - 1080p Series - Features PTZ Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTourTable mdxType="PTZTourTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`To start your tour return to the live video and open the preset positions menu above the video. Clicking on `}<strong parentName="p">{`Tour`}</strong>{` will start the tour for you:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "868px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b70b579103e8d01710582d44c1129706/748b0/1080p_Settings_Features_PTZ_Tour_b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.478260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAp0lEQVQI13XMLwvCQBQA8H3ElRVhcGV1weg3ELsiHvtTFgQFFWzaLCaLKJcU29JtuO29u3t7ot3fB/h55Cw5stYaa51z/Eff98yslCqKIgiCMAx93/daMB0YAISuRUQi90VExjAzbLbufmNm+r1KKSmlECKO4yiKvMnuOVq9DqfLYz+eyjzP0ixNZov0vJnzcYjJgJYCruvqDXWlm6ZBxLqutdZlWX4A1imV/EMmyf8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b70b579103e8d01710582d44c1129706/e4706/1080p_Settings_Features_PTZ_Tour_b.avif 230w", "/en/static/b70b579103e8d01710582d44c1129706/d1af7/1080p_Settings_Features_PTZ_Tour_b.avif 460w", "/en/static/b70b579103e8d01710582d44c1129706/67bb0/1080p_Settings_Features_PTZ_Tour_b.avif 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b70b579103e8d01710582d44c1129706/a0b58/1080p_Settings_Features_PTZ_Tour_b.webp 230w", "/en/static/b70b579103e8d01710582d44c1129706/bc10c/1080p_Settings_Features_PTZ_Tour_b.webp 460w", "/en/static/b70b579103e8d01710582d44c1129706/1ae05/1080p_Settings_Features_PTZ_Tour_b.webp 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b70b579103e8d01710582d44c1129706/81c8e/1080p_Settings_Features_PTZ_Tour_b.png 230w", "/en/static/b70b579103e8d01710582d44c1129706/08a84/1080p_Settings_Features_PTZ_Tour_b.png 460w", "/en/static/b70b579103e8d01710582d44c1129706/748b0/1080p_Settings_Features_PTZ_Tour_b.png 868w"],
              "sizes": "(max-width: 868px) 100vw, 868px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b70b579103e8d01710582d44c1129706/748b0/1080p_Settings_Features_PTZ_Tour_b.png",
              "alt": "Web User Interface - 1080p Series - Features PTZ Tour",
              "title": "Web User Interface - 1080p Series - Features PTZ Tour",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      